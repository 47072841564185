import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Backspace, BadgeTwoTone, DeleteForever, DeleteTwoTone } from '@mui/icons-material';
import CertificateIcon from '@mui/icons-material/WorkspacePremium';
import AnimateButton from 'ui-component/extended/AnimateButton';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import SendEmail from 'Components/SendEmail';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}
export default function VerificationPage() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const { eventId, role } = useParams();
    const [search, setSearch] = React.useState('');
    const { data, loading, error } = useQuery(GET_ME);
    const [tags, setTags] = React.useState([]);
    const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
        variables: { eventId, role }
    });
    React.useEffect(() => {
        if (featureData && featureData.mailVerificationFeature) {
            let parsedData = [];
            try {
                parsedData = JSON.parse(featureData.mailVerificationFeature);
            } catch (err) {
                console.error('Error parsing the data:', err);
            }
            if (Array.isArray(parsedData)) {
                setTags(parsedData);
            }
        }
    }, [featureData]);
    if (featureLoading) return <LinearProgress />;

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography>{error.message}</Typography>;
    }

    return (
        <Stack spacing={2}>
            <MainCard>
                <Grid spacing={gridSpacing} container={true}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" sx={{ bgcolor: 'background.paper', width: '100%', mb: 2 }}>
                            <Typography variant="h1" textAlign={'center'}>
                                Verify {role} Data
                            </Typography>
                            {data?.me?.permissions?.includes('find-' + role) && <SearchBox onSearch={setSearch} />}
                        </Stack>

                        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                            <AppBar position="relative" color="secondary">
                                <Tabs
                                    sx={{
                                        '& .Mui-selected': { background: '#05344f', color: 'white' },
                                        background: 'white',
                                        color: 'black'
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Pending Registration"
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Accepted Registration"
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Rejected Registration"
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="pending"
                                        permissions={data.me?.permissions?.split(',') || []}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="accept"
                                        permissions={data.me?.permissions?.split(',') || []}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="reject"
                                        permissions={data.me?.permissions?.split(',') || []}
                                    />
                                </TabPanel>
                            </SwipeableViews>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Stack>
    );
}

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 600 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

function DataTable({ eventId, role, search = '', permissions, tags, status }) {
    const limit = 100;
    const { data, loading, error, refetch } = useQuery(GET_VERIFIED_DATA, {
        variables: {
            eventId,
            role,
            status,
            conditions: {
                limit,
                text: search,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentAction, setCurrentAction] = React.useState({ id: null, value: null });
    const [modalMessage, setModalMessage] = React.useState('');
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleAcceptReject = (id, value) => {
        setCurrentAction({ id, value });
        if (value === 'accept') {
            setModalMessage(
                <>
                    Are you sure you wish to <b>Accept</b> this application?
                    <br />
                    <br />
                    An <b>Email</b> will be sent to the attendee upon <b>Acceptance</b>.
                </>
            );
        } else if (value === 'reject') {
            setModalMessage(
                <>
                    Are you sure you wish to <b>Reject</b> this application?
                    <br />
                    <br />
                    An <b>Email</b> will be sent to the attendee upon <b>Rejection</b>.
                </>
            );
        }
        setIsModalOpen(true);
    };

    const confirmAction = () => {
        setIsLoading(true);
        updateStatusandSendMail({
            variables: {
                eventId,
                role,
                id: currentAction.id,
                field: 'status',
                value: currentAction.value
            }
        })
            .then((r) => {
                setCurrentAction({ id: null, value: null });
                setModalMessage(r.data.result);
                setIsLoading(false);
                setIsModalOpen(true);
            })
            .catch((e) => {
                setModalMessage(e.message);
                setIsModalOpen(true);
                setIsLoading(false);
            });
        refetch;
    };

    const [updateStatusandSendMail] = useMutation(UPDATE_APPLICANT_STATUS_AND_MAIL, {
        onCompleted: refetch,
        onError: (e) => console.log(e)
    });
    const [updateField] = useMutation(UPDATE_APPLICANT_FIELD, {
        onCompleted: refetch,
        onError: (e) => console.log(e)
    });
    const [doDelete] = useMutation(DELETE_APPLICANT, { onCompleted: refetch });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }
    return (
        <Stack spacing={2}>
            <Box>
                <Stack direction="row" spacing={2}>
                    <AnimateButton>
                        <Button variant="contained" disabled={loading} onClick={() => refetch()}>
                            Refresh
                        </Button>
                    </AnimateButton>
                    {permissions.includes('admin') && permissions.includes('bulk-email') ? (
                        <SendEmail {...{ selectedIds, eventId, role }} />
                    ) : (
                        <div />
                    )}
                </Stack>
            </Box>
            <Box>
                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="message-dialog-title">
                    <DialogTitle id="message-dialog-title" sx={{ fontSize: 24 }}>
                        {!!currentAction.id ? 'Confirm Action' : 'Message'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{modalMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsModalOpen(false)} variant="outlined" color="error">
                            Close
                        </Button>
                        {!!currentAction.id && (
                            <>
                                <Button onClick={confirmAction} variant="contained" color="success">
                                    {isLoading ? <CircularProgress color="primary" size={20} /> : 'Confirm'}
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>

                <DataGrid
                    sx={{ height: 600 }}
                    paginationMode="server"
                    pageSize={limit}
                    rowsPerPageOptions={[limit]}
                    onPageChange={(p) =>
                        refetch({
                            conditions: {
                                offset: p * limit,
                                limit,
                                orderBy: 'created',
                                order: 'desc'
                            }
                        })
                    }
                    rowCount={data?.total || 0}
                    checkboxSelection={permissions.includes('admin') || permissions.includes('email')}
                    onSelectionModelChange={(ids) => {
                        setSelectedIds(
                            ids.map((id) => {
                                const applicant = JSON.parse(
                                    data?.verifiedApplicants?.find((d) => d.id === id)?.data || '{"email":"undefined"}'
                                );
                                return {
                                    id,
                                    email: applicant.email || applicant.Email
                                };
                            })
                        );
                    }}
                    onCellEditCommit={(p, e) =>
                        e.key === 'Enter' &&
                        updateField({
                            variables: {
                                eventId,
                                role,
                                id: p.id,
                                field: p.field,
                                value: p.value
                            }
                        })
                            .then((r) => window.alert(r.data.result))
                            .catch((e) => window.alert(e.message))
                    }
                    components={{
                        Toolbar: permissions.includes('exp-' + role) ? GridToolbar : ''
                    }}
                    columns={[
                        {
                            type: 'actions',
                            field: 'actions',
                            headerName: 'Actions',
                            width: 150,
                            getActions: (p) =>
                                [
                                    permissions.includes('del-' + role) && (
                                        <GridActionsCellItem
                                            label="Delete"
                                            onClick={() => {
                                                if (!window.confirm('Are you sure you want to delete this?')) {
                                                    return;
                                                }
                                                doDelete({ variables: { eventId, role, id: p.id } })
                                                    .catch((e) => window.alert(e.message))
                                                    .then((r) => window.alert(r.data.result));
                                            }}
                                            icon={<DeleteTwoTone color="error" />}
                                        />
                                    ),
                                    permissions.includes('admin') && tags.includes('emailVerification') && (
                                        <React.Fragment>
                                            {(p.row.status === 'pending' || p.row.status === 'reject') && (
                                                <GridActionsCellItem
                                                    label="Accept"
                                                    onClick={() => handleAcceptReject(p.id, 'accept')}
                                                    icon={<ThumbUpOffAltIcon color="success" />}
                                                />
                                            )}
                                            {(p.row.status === 'pending' || p.row.status === 'accept') && (
                                                <GridActionsCellItem
                                                    label="Reject"
                                                    onClick={() => handleAcceptReject(p.id, 'reject')}
                                                    icon={<ThumbDownOffAltIcon color="error" />}
                                                />
                                            )}
                                            {p.row.status === 'accept' && (
                                                <GridActionsCellItem
                                                    label="Badge"
                                                    onClick={() =>
                                                        window.open(
                                                            `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${p.id}`
                                                        )
                                                    }
                                                    icon={<BadgeTwoTone />}
                                                />
                                            )}
                                        </React.Fragment>
                                    )
                                ].filter(Boolean)
                        },
                        { field: 'id', width: 150 },

                        {
                            field: 'created',
                            width: 200,
                            valueFormatter: (row) => format(+row.value, 'dd-MMM-yyyy hh:mm a')
                        },
                        { field: 'regLink', width: 300, headerName: 'Registration Link' },

                        ...data?.form.fields.map((f) => ({
                            field: f.name,
                            width: 200,
                            editable: permissions.includes('edit-' + role)
                        }))
                    ]}
                    rows={data?.verifiedApplicants.map((row) => ({
                        ...row,
                        ...JSON.parse(row.data)
                    }))}
                />
            </Box>
        </Stack>
    );
}

export const GET_VERIFIED_DATA = gql`
    query ($eventId: String!, $role: ROLE!, $status: String, $conditions: InputConditions) {
        verifiedApplicants(eventId: $eventId, role: $role, status: $status, conditions: $conditions) {
            serial
            id
            created
            regLink
            data
        }
        total: verifiedApplicantsCount(eventId: $eventId, role: $role, status: $status, conditions: $conditions)

        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;

const UPDATE_APPLICANT_FIELD = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!, $field: String!, $value: String!) {
        result: updateApplicantField(eventId: $eventId, role: $role, id: $id, field: $field, value: $value)
    }
`;
const UPDATE_APPLICANT_STATUS_AND_MAIL = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!, $field: String!, $value: String!) {
        result: updateApplicantStatusandMail(eventId: $eventId, role: $role, id: $id, field: $field, value: $value)
    }
`;

const DELETE_APPLICANT = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!) {
        result: deleteApplicant(eventId: $eventId, role: $role, id: $id)
    }
`;

const GET_ME = gql`
    query {
        me {
            permissions
        }
    }
`;
export const GET_VERIFICATION_FEATURE = gql`
    query ($eventId: String!, $role: ROLE!) {
        mailVerificationFeature(eventId: $eventId, role: $role)
    }
`;
