import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import LeadGenStaffCard from './LeadGenStaffCard';

const GET_STANDS_BY_EVENT_ID = gql`
    query GetStandsByEventId($eventId: String!) {
        standsByEventId(eventId: $eventId)
    }
`;

export default function LeadGen() {
    const { eventId } = useParams();
    const { loading, error, data } = useQuery(GET_STANDS_BY_EVENT_ID, {
        variables: { eventId }
    });
    const navigate = useNavigate();

    const stands = (data && data?.standsByEventId && JSON.parse(data?.standsByEventId)) || [];

    return (
        <div>
            <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Lead Generation Data
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {stands
                    ?.sort((a, b) => (a.company > b.company ? 1 : -1))
                    .map((stand, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={index}>
                            <LeadGenCard stand={stand} type={'admin'} />
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
}
