import { gql, useQuery } from '@apollo/client';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function BulkPrint({ selectedIds, eventId, role }) {
    const [open, setOpen] = React.useState(false);
    const close = () => setOpen(false);
    return (
        <>
            <Dialog fullWidth maxWidth="md" open={open} onClose={close}>
                <DialogTitle>Bulk Print</DialogTitle>
                <DialogContent>
                    <Box p={2}>
                        <BulkPrintProcess {...{ selectedIds, eventId, role }} />
                    </Box>
                </DialogContent>
            </Dialog>
            {selectedIds.length > 0 && (
                <Button variant="contained" color="error" onClick={() => setOpen(true)}>
                    Bulk Print ({selectedIds.length})
                </Button>
            )}
        </>
    );
}
async function fetchBadgeDesigns() {
    try {
        const res = await query(
            `query{
                 designs:badgeDesigns{
                  role
                  fields
                 }
             }`
        );
        // if (!res.applicant) {
        //   throw new Error("Applicant not found");
        // }
        return res.designs;
    } catch (e) {
        console.log(e);
    }
    return null;
}
export function BulkPrintProcess({ selectedIds, eventId, role }) {
    const [pdfSize, setPdfSize] = React.useState('');
    const [designs, setDesigns] = React.useState(null);
    const { data, loading, error } = useQuery(gql`
        query {
            designs: badgeDesigns {
                role
                fields
            }
        }
    `);
    if (data && data?.designs) {
        setDesigns(data?.designs);
    }

    const handleChange = (event) => {
        setPdfSize(event.target.value);
    };
    React.useEffect(() => {
        console.log(selectedIds);
    }, []);
    return (
        <Box>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="pdfSize-label">PDF Size</InputLabel>
                <Select labelId="pdfSize-label" id="pdfSize" value={pdfSize} onChange={handleChange} label="PDF Size">
                    {/* Letter Sizes */}
                    <MenuItem value="792x612">Letter Landscape (792x612)</MenuItem>
                    <MenuItem value="612x792">Letter Portrait (612x792)</MenuItem>
                    <MenuItem value="1008x612">Legal Landscape (1008x612)</MenuItem>
                    <MenuItem value="612x1008">Legal Portrait (612x1008)</MenuItem>
                    <MenuItem value="720x540">Note Landscape (720x540)</MenuItem>
                    <MenuItem value="540x720">Note Portrait (540x720)</MenuItem>
                    <MenuItem value="1224x792">Tabloid Landscape (1224x792)</MenuItem>
                    <MenuItem value="792x1224">Tabloid Portrait (792x1224)</MenuItem>
                    <MenuItem value="792x1224">Ledger Landscape (792x1224)</MenuItem>
                    <MenuItem value="1224x792">Ledger Portrait (1224x792)</MenuItem>
                    <MenuItem value="416x283">Postcard Landscape (416x283)</MenuItem>
                    <MenuItem value="283x416">Postcard Portrait (283x416)</MenuItem>
                    <MenuItem value="756x283">Executive Landscape (756x283)</MenuItem>
                    <MenuItem value="522x756">Executive Portrait (522x756)</MenuItem>

                    {/* A Sizes */}
                    <MenuItem value="1191x842">A3 Landscape (1191x842)</MenuItem>
                    <MenuItem value="842x1191">A3 Portrait (842x1191)</MenuItem>
                    <MenuItem value="842x595">A4 Landscape (842x595)</MenuItem>
                    <MenuItem value="595x842">A4 Portrait (595x842)</MenuItem>
                    <MenuItem value="595x420">A5 Landscape (595x420)</MenuItem>
                    <MenuItem value="420x595" selected>
                        A5 Portrait (420x595)
                    </MenuItem>

                    {/* B Sizes */}
                    <MenuItem value="1000x708">B4 Landscape (1000x708)</MenuItem>
                    <MenuItem value="708x1000">B4 Portrait (708x1000)</MenuItem>
                    <MenuItem value="708x498">B5 Landscape (708x498)</MenuItem>
                    <MenuItem value="498x708">B5 Portrait (498x708)</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
